.add-pro-btn {
    width: fit-content;
    margin-left: auto !important;
    margin-bottom: 1.5rem;
}

.flex-box-column {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.filter-set-section {
    min-height: 30rem;
    max-height: 35rem;
    overflow: auto;
}

.filter-set-container {
    justify-content: flex-start !important;
    /* max-height: 30rem;
    overflow: auto; */
}

.filter-set {
    position: relative;
    width: 28%;
    padding: 2rem;
    margin: 1rem;
    margin-right: 2rem;
}

.filters-div {
    background-color: white;
}

.sister-store_filter_buttons {
    margin-top: -2rem;
    margin-bottom: 5rem;
}

.close-card__icon {
    cursor: pointer;
    position: absolute;
    color: gray;
    font-size: 16px !important;
    top: 0;
    right: 0;
    padding: 5px;
}

.close-card__icon:hover {
    background-color: whitesmoke;
    opacity: 0.8;
}

.grey_out_column {
    background: lightgrey;
    width: 100%; 
    height: 100%; 
    padding: 3px;
}