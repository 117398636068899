.product__store__status__container{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 18px 24px 10px;
    width: 100%;
    max-width: 100%;
}

.modal__div {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10001;
}

.expand__text {
    width: 140px;
}

.store-grp-modal__btn {
    margin-right: 2rem;
}

.fn__btn {
    padding: 0 1rem !important;
}

.store__status__table{
    display: flex;
    padding: 5rem 1rem 1rem;
}

.config-header {
    padding: 1rem;
}

.config-btn-container{
    padding: 5px;
    border: 1px solid #44677b;
    border-radius: 5px;
    background-color: #ebf4fb;
    cursor: pointer;
}

.config-btn-container >p{
    color: #44677b;
}

.create-config-btn {
    width: 25px;
    height: 25px;
}

.add-action-btn {
    color: #7f7e7f;
    border: 1px solid;
    background-color: white !important;
}

.cross-action-btn {
    color: white;
}

.create-pp-btn {
    width: fit-content;
    align-self: flex-end;
}

.missing-config__table {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

.red-text {
    color: red;
}

.green-text {
    color: green
}

.red-btn {
    background-color: red;
}

.green-btn {
    background-color: green;
}
.green-btn, .red-btn {
    color: white !important;
    font-weight: bold;
}

.red-btn:hover {
    background-color: red;
    opacity: 0.8;
}

.green-btn:hover {
    background-color: green !important;
    opacity: 0.8;
}