.finalize__container{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 24px 18px 24px 10px;
    width: 100%;
    max-width: 100%;
}

.store__view__tables {
    display: flex;
    flex-direction: column;
}

.f__table {
    margin: 20px 0;
    width: 100%;
}

.finalize__container__button {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.finalize__btn {
    margin-bottom: 2rem;
}

.finalize__tabs__body {
    position: relative;
}

.back__btn {
    position: absolute;
    right: 0;
    margin: 1rem;
    margin-top: 2rem;
}

.plan__details {
    font-size: 14px;
}
.comments_btn {
    position: absolute;
    right: 30px;
}

.comments_text {
    margin-left: 4rem;
}

#date-picker-portal {
    z-index: 9999;
  }