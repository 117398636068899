.section_container {
    background-color: #fff;
    padding: 1rem 1rem 4.5rem;
    margin: 1rem;
}

.store-search-container {
    padding: 1% 0% 1% 1%;
    margin: 0% 0% 1% 1%;
    max-height: 50rem;
    overflow: auto;
}

.non-editable-labels {
    font-weight: 600;
    font-size: 1.2rem;
    width: 16%;
}

.non-editable-text-field {
    width: 15%;
    margin: 0% 0% 2% 2%;
}

.opening-date-cal {
    height: 90%;
    /* width: 15%;
    margin-left: 2%; */
}

.opening-date-cal > input {
    text-align: center;
    opacity: 1;
    font-weight: 530;
    color: black !important;
    line-height: 1.8;
    font-size: 1.1rem;
}

.opening-date-cal .p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: #ced4da;
}

.opening-date-cal .p-inputtext:enabled:hover {
    border-color: #ced4da;
}

.opening-date-cal .p-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.opening-date-cal .p-button:enabled:hover {
    background: #0000002b;
    color: #ffffff;
    border-color: inherit; 
}

.flex-div .p-disabled, .p-component:disabled {
    opacity: 1;
    font-weight: 530;
    text-align: center;
    color: black !important;
    border: 1px solid #ddd;
    line-height: 1.8;
    font-size: 1.1rem;
}

.sister-store-container {
    padding: 2% 3% 2% 1%;
    margin-left: 1%;
}

.sister-store-table {
    width: fit-content;
}

#NEW_STORE_SELECTOR {
    margin: 0% 0% 2% 2%;
    height: 3rem;
    /* width: 15%; */
}

#NEW_SISTER_STORE_SELECTOR {
    margin: 0% 0% 2% 2%;
    height: 3rem;
    /* width: 15%; */
}

#DC_IMPORT_BUTTON {
    padding: 0.5rem;
    font-size: 1.2rem;
    margin-top: 2%;
    position: absolute;
    left: 42%;
    width: 16%;
    font-weight: bold;
}

#STORE_SEARCH_CONTAINER {
    display: grid;
    grid-template-columns: 30% 27% 25%;
    column-gap: 7rem;
}

.label-35 {
    width: 35% !important;
}

.store-info-container {
    width: 33%;
}

.filter-card__container {
    /* margin: 3rem 0; 
    padding: 3rem 0; */
    text-align: center;
    /* width: 240px; */
    box-sizing: border-box;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.5);
    border-radius: 15px;
    background-color: whitesmoke;
    font-weight: bold;
}
  
.filter-card__container > span {
    font-size: small;
}
  
.filter-card__container > h4 {
    padding-bottom: 1rem;
    color: gray;
}
  